@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'zolo-text-bold';
  src: url('./fonts/Karla-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'zolo-text';
  src: url('./fonts/Karla-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'zolo-header';
  src: url('./fonts/ABCWhyteInktrap-Bold-Trial.otf') format('truetype');
}

body {
  margin: 0;
  font-family: zolo-text !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  font-size: 16px !important;
  }

/* Bring google api autocomplete dropdown forward */
.pac-container {
  z-index: 99999999;
}

h1 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4rem;
  color: #265542;
  font-family: zolo-header;
}

h2 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #457662;
  font-family: zolo-header;
}